import { ImageBackgroundProps } from "@/types";
import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

import ReactMarkdown from 'react-markdown'
import NextSection from "../NextSection";
import cx from 'classnames'

const ImageBackground: React.FC<ImageBackgroundProps> = ({ backgroundImage, text, className }) => {
    const background = getImage(backgroundImage.gatsbyImageData);
    const bg = convertToBgImage(background)

    const scrollDown = () => {
        const section = document.querySelector('#vacancies');
        return section ? section.scrollIntoView({ behavior: 'smooth', block: 'start' }) : false;
    }

    return (
        <BackgroundImage 
            Tag={'section'} 
            {...bg}
            style={{
                position: 'relative',
            }}>
            <div id="imageBackground" className={cx(`py-28 md:py-40 lg:py-0 max-w-4xl text-center w-11/12 mx-auto flex items-center justify-center`, className)}>
                <ReactMarkdown children={text} className="markdown text-white" />
            </div>

            <NextSection {...{
                className: 'text-white',
                color: '#fff',
                text: 'Vacancies',
                clickHandler: () => scrollDown()
            }} />
        </BackgroundImage>
    )
}

export default ImageBackground