import Button from "@/components/Button"
import CareerCard from "@/components/CareerCard"
import Select from "@/components/Inputs/Select"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"

import { CareersArchiveProps } from "@/types"
import cx from "classnames"

import { parseISO, isBefore } from "date-fns"
import NextSection from "../NextSection"

const GetCareers = () => {
  const { allWorkableJob } = useStaticQuery(graphql`
    query getCareers {
      allWorkableJob(
        filter: {
          department_hierarchy: { elemMatch: { name: { eq: "Elevation" } } }
        }
      ) {
        edges {
          node {
            id
            application_url
            benefits
            department
            description
            employment_type
            requirements
            shortcode
            title
            created_at
            # keywords
          }
        }
      }
    }
  `)

  return allWorkableJob.edges
}

const CareersArchive: React.FC<CareersArchiveProps> = ({
  heading,
  showFilter = true,
  showFirst: first = 4,
  verticalLabel,
  className,
}) => {
  const [showFirst, setShowFirst] = useState(first)
  const [discipline, setDiscipline] = useState("")
  const careers = GetCareers() || []

  const disciplines = [
    ...new Set(careers.map(({ node: { keywords } }) => keywords) || []),
  ]

  const flattenedDisciplines = [...new Set(disciplines.flat())]

  const filteredDisciplines = careers.filter(
    ({ node: career }) =>
      discipline === "" || career?.keywords?.includes(discipline)
  )

  const _careers = [].concat(careers).sort(({ node: a }, { node: b }) => {
    return !isBefore(parseISO(a?.created_at), parseISO(b?.created_at)) ? -1 : 1
  })

  return (
    <section
      id="vacancies"
      className={cx(
        "py-28 md:py-40 lg:py-0 bg-gallery relative flex items-center justify-center",
        className
      )}
    >
      <div className="container mx-auto relative px-10 grid grid-cols-1 md:grid-cols-14">
        <div className="col-span-full xl:col-span-12 xl:col-start-2">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between md:mb-12">
            <h2 className="flex-1 text-3xl xl:text-4xl text-red font-bold uppercase">
              {heading}
            </h2>

            {/* {showFilter && (
                            <Select
                                size="lg"
                                value={discipline}
                                setValue={setDiscipline}
                                options={[
                                    {
                                        value: '',
                                        label: 'Filter by discipline',
                                    },
                                    ...flattenedDisciplines.map(
                                        (discipline) => ({
                                            value: discipline,
                                            label: discipline,
                                        })
                                    ),
                                ]}
                                className="flex-1 mt-8 md:mt-0 relative z-10"
                            />
                        )} */}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8 md:mt-0">
            {_careers?.length > 0 ? (
              <>
                {_careers.map(({ node: career }, i) => (
                  <CareerCard
                    key={i}
                    {...{
                      key: i,
                      hide:
                        (i >= showFirst && discipline === "") ||
                        (
                          filteredDisciplines.find(
                            ({ node: { id } }) => id === career?.id
                          ) || []
                        ).length === 0,
                      ...career,
                    }}
                  />
                ))}{" "}
              </>
            ) : (
              <p className="col-span-full w-full text-lg md:text-xl">
                No open vacancies, please check back in the future.
              </p>
            )}
          </div>
        </div>
      </div>
      {showFirst < careers.length &&
      filteredDisciplines.length === careers.length ? (
        <NextSection
          {...{
            className: "text-black",
            color: "#000",
            text: "Load more",
            clickHandler: () => setShowFirst(showFirst + 4),
          }}
        />
      ) : null}
    </section>
  )
}

export default CareersArchive
