import { getImage, GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import cx from 'classnames';

interface CarouselHeroCardProps {
    carouselImage: {
        gatsbyImageData: IGatsbyImageData
    }
    className: string;
}


const CarouselHeroCard: React.FC<CarouselHeroCardProps> = ({ carouselImage, className }) => {
    const image = getImage(carouselImage.gatsbyImageData);

    return (
        <div className={cx('top-0 left-0 h-full w-full', className)}>
            <GatsbyImage image={image} alt={'Carousel Item'} objectFit={'cover'} objectPosition={'center'} className="w-full h-full" />
        </div>
    )
}

export default CarouselHeroCard;