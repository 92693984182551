import React, { useState } from 'react';
import { CarouselHeroProps } from '@/types';
import CarouselHeroCard from '../CarouselHeroCard';
import { useEffect } from 'react';
import { useRef } from 'react';
import Icon from '../Icon';

import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import cx from 'classnames';
import NextSection from '../NextSection';

import { useSwipeable } from 'react-swipeable';

import './styles.scss';

const CarouselHero: React.FC<CarouselHeroProps> = ({ id, items, logo, className }) => {

    const [activeSlide, setActiveSlide] = useState<number>(0);
    const [active, setActive] = useState<boolean>(true);
    const [direction, setDirection] = useState<number>(1);
    const [paused, setPaused] = useState<boolean>(false);

    const logoImage = getImage(logo.gatsbyImageData);

    const updateIndex = (newIndex: number) => {
        if (newIndex < 0) newIndex = items.length - 1;
        else if (newIndex >= items.length) newIndex = 0;

        setActiveSlide(newIndex);
    }

    const scrollDown = () => {
        const section = document.querySelector('#imageBackground');
        return section ? section.scrollIntoView({ behavior: 'smooth', block: 'start' }) : false;
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeSlide + 1),
        onSwipedRight: () => updateIndex(activeSlide - 1),
        trackMouse: true
    })

    useEffect(() => {
        const interval = setInterval(() => {
            if (!paused) updateIndex(activeSlide + 1)
        }, 6000)

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    });

    const navigate = ({ forceDirection = null }) => {
        const offset = (window.innerWidth > 768) ? (window.innerWidth > 1280) ? 2 : 2 : 1

        if (!forceDirection) {
            if (activeSlide === 1 && direction === 0) {
                setDirection(1)
            } else if (activeSlide === (items.length - offset) && direction === 1) {
                setDirection(0)
            }
        }

        if (forceDirection === 0 || ((forceDirection === null) && direction === 0)) {
            updateIndex(activeSlide - 1);
        }
        if (forceDirection === 1 || ((forceDirection === null) && direction === 1)) {
            updateIndex(activeSlide + 1);
        }
    }

    const handlePrevClick = () => {
        setActive(false)
        navigate({ forceDirection: 0 });
        setTimeout(() => {
            setActive(true)
        }, 5000)
    }

    const handleNextClick = () => {
        setActive(false)
        navigate({ forceDirection: 1 })
        setTimeout(() => {
            setActive(true)
        }, 5000)
    }

    return (
        <section className={cx(`relative overflow-hidden pt-[75px] lg:pt-[76px] hero-carousel`, className)}>
            <div className="carousel lg:cursor-grab overflow-hidden" {...handlers}>
                <div className="bg-black cursor-pointer shadow-md bg-opacity-20 top-0 w-8 left-0 absolute lg:w-16 px-1 lg:px-4 h-full z-20 flex items-center justify-center backdrop-blur-lg group" onClick={() => handlePrevClick()} onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>
                    <Icon {...{
                        name: 'angleDown',
                        className: 'transform rotate-90 text-white w-20 h-20 !pointer-events-none group-hover:scale-105 group-hover:text-red transition-all'
                    }} />
                </div>

                <div className="absolute overflow-visible top-[40%] lg:top-[45%] right-0 lg:pr-28 left-0 z-20 max-w-screen-2xl mx-auto flex items-end justify-end lg:w-full w-1/2 !pointer-events-none">
                    <div className="xl:max-w-[750px] max-w-[650px]">
                        <GatsbyImage image={logoImage} alt={'nDreams Elevation'} objectFit={'contain'} objectPosition={'center'} />
                    </div>
                </div>

                <div className="transition-all z-10">
                    <CarouselHeroCard {...{
                        className: 'w-full min-h-[425px] h-full pointer-events-none -z-10 opacity-0',
                        ...items[0]
                    }} />
                    {items.map((item, i) => <CarouselHeroCard {...{
                        className: `${activeSlide === i ? 'opacity-100' : 'opacity-0'
                            } transition-all duration-1000 pointer-events-none absolute`,
                        ...item
                    }} />)}
                </div>

                <div className="bg-black shadow-md cursor-pointer bg-opacity-20 top-0 right-0 absolute w-8 lg:w-16 px-1 lg:px-4 h-full z-20 flex items-center justify-center backdrop-blur-lg group" onClick={() => handleNextClick()} onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>
                    <Icon {...{
                        name: 'angleDown',
                        className: 'transform -rotate-90 text-white w-20 h-20 !pointer-events-none group-hover:scale-105 group-hover:text-red transition-all'
                    }} />
                </div>
            </div>

            <NextSection {...{
                className: 'text-white',
                color: '#fff',
                text: 'Discover',
                clickHandler: () => scrollDown()
            }} />
        </section>
    )
}

export default CarouselHero;