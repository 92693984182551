import Blades from "@/components/Blades"
import { Layout } from "@/hoc/Layout"
import { BaseComponentProps, CareersArchiveProps } from "@/types"
import { graphql } from "gatsby"
import React from "react"

import { CarouselHeroProps, ImageBackgroundProps } from "@/types"
import Meta from "@/components/Meta"

interface PageProps extends BaseComponentProps {
  data: {
    page: {
      __typename: string
      metadata: {
        description: string
        title: string
        twitterCard: string
        image: {
          url: string
        }
      }
      id: string
      pageSlug: string
      pageTitle: string
      blades: (CarouselHeroProps | ImageBackgroundProps | CareersArchiveProps)[]
    }
  }
}

const Page: React.FC<PageProps> = ({ data }) => {
  const page = data?.page
  const blades = page?.blades
  const seo = page?.metadata

  return (
    <>
      <Meta {...{ seo }} />
      <Blades {...{ blades }} />
    </>
  )
}

export default Page

export const query = graphql`
  query getPage($id: String) {
    page: datoCmsElevationPage(id: { eq: $id }) {
      __typename
      metadata {
        description
        title
        twitterCard
        image {
          url
        }
      }
      id
      pageSlug
      pageTitle
      blades {
        ... on DatoCmsCarouselHeroElevation {
          ...CarouselHero
        }
        ... on DatoCmsImageBackgroundElevation {
          ...ImageBackground
        }
        ... on DatoCmsCareersArchive {
          ...CareersArchive
        }
        ... on DatoCmsPressReleaseBlock {
          ...PressReleases
        }
      }
    }
  }
`
