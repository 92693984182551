import {
  CarouselHeroProps,
  ImageBackgroundProps,
  PressReleasesProps,
} from "@/types"
import React from "react"
import CareersArchive from "../CareersArchive"
import CarouselHero from "../CarouselHero"
import ImageBackground from "../ImageBackground"
import PressReleases from "../PressReleases"

interface BladesProps {
  blades?: (CarouselHeroProps | ImageBackgroundProps | PressReleasesProps)[]
  game?: string
}

const getBladeType = ({ key, type, game, props }) => {
  switch (type) {
    case "DatoCmsImageBackgroundElevation":
      return (
        <ImageBackground
          {...{ key, className: "lg:min-h-[100vh] h-full", ...props }}
        />
      )
    case "DatoCmsCarouselHeroElevation":
      return (
        <CarouselHero
          {...{ key, className: "lg:min-h-[100vh] h-full", ...props }}
        />
      )
    case "DatoCmsCareersArchive":
      return (
        <CareersArchive
          {...{ key, className: "lg:min-h-[100vh] h-full", ...props }}
        />
      )
    case "DatoCmsPressReleaseBlock":
      return (
        <PressReleases
          {...{ key, className: "lg:min-h-[100vh] h-full", ...props }}
        />
      )
    default:
      return "DEFAULT"
  }
}

const Blades: React.FC<BladesProps> = ({ blades = [], game }) =>
  blades.map(({ __typename: type, ...props }, i) =>
    getBladeType({ key: i, type, game, props })
  )

export default Blades
