import React from 'react';
import cx from 'classnames'

import './styles.scss';

interface NextSectionProps {
    className?: string;
    text: string;
    color?: string;
    clickHandler: () => void;
}

const NextSection: React.FC<NextSectionProps> = ({
    className,
    text,
    color = "#fff",
    clickHandler
}) => {
    return (
        <div className={cx(`absolute cursor-pointer -bottom-0 left-1/2 -translate-x-1/2 group flex flex-col items-center gap-2 justify-between floating-link`, className)} onClick={() => clickHandler()}>
            <h3 className="uppercase font-bold lg:text-2xl">{text}</h3>

            <svg width="30px" height="50px" viewBox="0 0 10 10" className="h-[30px] lg:h-[40px] group-hover:h-[50px] transition-all duration-600">
                <line stroke={color} strokeWidth={0.5} x1="5" x2="5" y1="0" y2="15" />
            </svg>
        </div>
    )
}

export default NextSection;