import React from 'react';
import cx from 'classnames'

import { trimString } from '@/config';

import Link from '../Link'
import Icon from '../Icon';

import './styles.scss';

interface CareerCardProps {
    title: string;
    shortcode: string;
    description: string;
    keywords: string[];
    department: string;
    hide?: boolean;
    className?: string;
}

const CareerCard: React.FC<CareerCardProps> = ({
    title,
    shortcode,
    description,
    keywords,
    department,
    hide = false,
    className
}) => (
    <div
        className={cx(
            'career-card group transform origin-center hover:scale-105 hover:z-20 transition duration-200 bg-aldo border relative border-aldo career-card-clip',
            className,
            { hidden: hide }
        )}
    >
        <a
            href={`https://ndreams.com/careers/${shortcode}`}
            rel="noreferrer noopenner"
            className="flex flex-col min-h-full overflow-hidden bg-white career-card-inner"
        >
            <div className="flex-1 p-6 career-card__inner">
                <div className="mb-8 flex items-start justify-between">
                    <h3 className="text-2xl text-black font-bold">{title}</h3>
                    {/* <Icon
                        name={keywords && keywords[0].toLowerCase()}
                        className="career-card__icon ml-4 text-primary"
                        alt={department}
                    /> */}
                </div>
                {/* <p dangerouslySetInnerHTML={{ __html: trimString({ str: getFirstParagraph(description) }) }} /> */}
            </div>
            <div className="career-card__link p-6 relative border-t border-dashed border-aldo overflow-hidden">
                <span className="relative text-red group-hover:text-primary text-lg font-bold uppercase z-10">
                    Apply
                </span>
            </div>
        </a>
    </div>
);

export default CareerCard;