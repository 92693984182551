import React from 'react';
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby';

const GetDefaults = () => {
    const { favicon, site } = useStaticQuery(graphql`
        query getDefaults {
            favicon: datoCmsFaviconMetaTags {
                tags
            }
            site: datoCmsElevationGlobalSeo {
                websiteName
                twitterAccount
                titleSuffix
                fallbackTitle
                fallbackDescription
                facebookPageAddress
                sharingImage {
                    url
                }
            }
        }
    `)

    return {
        ...favicon,
        ...site,
    }
};

const Meta = ({
    seo
}) => {
    const defaults = GetDefaults();
    const title = seo?.title ?? `${defaults?.websiteName} ${defaults?.titleSuffix}`
    const description = seo?.description ?? defaults?.description
    const image = seo?.image?.url ?? defaults?.sharingImage?.url
    const cardType = seo?.twitterCard ?? "summary_large_image"

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:card" content={cardType} />

            {defaults.noIndex && <meta name="robots" content="noindex" />}

            {defaults.tags.map(({ tagName, attributes }, i) => (tagName === 'link') && (
                <link
                    key={i}
                    href={attributes?.href}
                    rel={attributes?.rel}
                    sizes={attributes?.sizes}
                />
            ))}
        </Helmet>
    )
}

export default Meta