import React from "react"
import cx from "classnames"

import { getImage, GatsbyImage } from "gatsby-plugin-image"

import "./styles.scss"

const PressReleaseCard = ({ title, image, externalLink, hide }) => {
  const img = getImage(image.gatsbyImageData)
  return (
    <div
      className={cx(
        "press-release-card group transform origin-center hover:scale-105 hover:z-20 transition duration-200 relative z-20",
        { hidden: hide }
      )}
    >
      <a
        rel="noreferrer noopenner"
        className="flex flex-col min-h-full overflow-hidden"
        target="_blank"
        href={externalLink}
      >
        <div className="flex-1 press-release-card__inner">
          <div className="w-full aspect-[16/10] relative overflow-hidden">
            <GatsbyImage
              image={img}
              alt="something"
              objectFit="cover"
              objectPosition="center"
              className=" w-full h-full z-0 max-h-screen max-w-none absolute top-0 left-0"
            />
          </div>
          <div className="p-6 mb-8 flex items-start justify-between">
            <h3 className="text-2xl text-black font-bold">{title}</h3>
          </div>
        </div>
        <div className="press-release-card__link p-6 relative border-t border-dashed border-aldo overflow-hidden">
          <span className="relative text-red text-lg font-bold uppercase z-10">
            Read Story
          </span>
        </div>
      </a>
    </div>
  )
}

export default PressReleaseCard
